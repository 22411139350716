import { Component, OnInit, OnDestroy } from "@angular/core";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { ThemeService } from "./theme/components/theme.service";
import { MessageBroadcasterService, MessageTypes } from "./services/message.service";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./services/authentication.service";
import { HttpClient } from "@angular/common/http";
import { ChatService } from "./pages/chat/chat.service";
import { LoggerService } from "./core/logger.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { SocketsService } from "./sockets.service";
import { ToastService } from "./core/toast.service";
import { LanguageService } from "./core/language.service";
import { NotificationsService } from "./services/notifications.service";
import { NotificationTypes } from "./services/app.constants";

declare let $: any;
// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          "margin-left": "280px",
        })
      ),
      state(
        "out",
        style({
          "margin-left": "0px",
        })
      ),
      transition("in => out", [animate("200ms ease-in")]),
      transition("out => in", [animate("200ms ease-in")]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "Corral";
  isLoaderActive = true;
  checkedCriticalEvents = false;

  constructor(
    private translate: TranslateService,
    private themeService: ThemeService,
    private messageService: MessageBroadcasterService,
    public authService: AuthenticationService,
    private logger: LoggerService,
    private router: Router,
    private service: SocketsService,
    private toastService: ToastService,
    private languageService: LanguageService,
    private notificationService: NotificationsService,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.languageService.setDefaultLanguage();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {});

    this.service.initSocketSubscriptions();

    this.authService.loginSuccess.subscribe(() => {
      this.service.authenticateSocketIo();
      this.chatService.checkIfUserHasUnread();
      this.checkedCriticalEvents = false;
      if (this.authService.hasRole("ROLE_DOCTOR")) {
        this.checkCriticalEvents();
      }
    });
    if (this.authService.isUserAuthenticated()) {
      this.service.authenticateSocketIo();
      this.chatService.checkIfUserHasUnread();
      if (this.authService.hasRole("ROLE_DOCTOR")) {
        this.checkCriticalEvents();
      }
    }

    this.authService.logoutSuccess.subscribe((data) => {
      this.service.logoutSockets(data);
    });

    this.router.events.subscribe((x) => {
      if (x instanceof NavigationEnd) {
        // gtag("config", "G-THVF52ES6Y", {
        //   page_path: (x as NavigationEnd).urlAfterRedirects,
        // });
        const xx = x as NavigationEnd;
        if (xx.url === "/chat") {
          this.isLoaderActive = false;
        } else {
          this.isLoaderActive = true;
        }
      }
      window.scrollTo(0, 0);
    });

    this.messageService.patientCriticalEvent.subscribe((x) => {
      this.toastService.warninngMessage(
        `${this.translate.instant("general.Patient")} ${x.data.patientName} ${this.translate.instant(
          "general.hadACriticalEvent"
        )}`
      );
    });
  }

  ngOnDestroy() {
    this.messageService.patientCriticalEvent.unsubscribe();
  }

  get isOpen() {
    return this.themeService.animationState;
  }

  checkCriticalEvents() {
    if (!this.checkedCriticalEvents) {
      this.notificationService
        .getAllNotifications(false, undefined, NotificationTypes.dssRule)
        .subscribe((notifications: any) => {
          if (notifications && notifications.unread > 0) {
            this.toastService.confirmDeleteWithMessage(
              this.translate.instant(`notificationsPage.criticalNotification`, {
                unreadCriticalEvents: notifications.unread,
              }),
              () => this.router.navigateByUrl("critical-notifications")
            );
          }
          this.checkedCriticalEvents = true;
        });
    }
  }
}
