export const environment = {
  production: true,
  serverPath: "/rest",
  defaultLang: "en",
  dateDefaultFormat: "YYYY-MM-DD",
  sockets: {
    url: "/",
    path: "/ws",
    transports: ["websocket"],
  },
  daysForConsentExpirationNotification: 7,
  demoCredentials: {
    email: "",
    password: "",
  },
  analysisDemo: false,
  agentUrl: "http://vassistant.iti.gr:9165/webhooks/rest/webhook",
  apkUrl: "/assets/mainapp9.apk",
  motionApkUrl: "/assets/motion3.apk",
  streamChatUrl: "wss://heca.iti.gr/assistant-api/ws/5e60089c-0c8e-4897-a508-1cc370c42d3f",
  trendUrl: "http://160.40.52.229:7000"
};
