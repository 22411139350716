<div class="chatbot-ui-container">
  <div
    class="chatbot-ui-toggle-button"
    (click)="toggleMessages('chatbot')"
    *ngIf="showChatBot"
    #chatButton
    (isMoving)="chatButtonMoved()"
    appGGDraggable
  >
    <i class="fas fa-comments"></i>
  </div>
  <div class="chatbot-outer-container" [ngStyle]="{ transform: containerTransform }">
    <ng-container *ngFor="let chatGroup of chats">
      <ng-container *ngIf="!isAdmin || isAdmin">
        <div class="chatbot-ui-chats-container" *ngIf="chatGroup.isVisible" [class.visible]="chatGroup.isVisible">
          <div class="chat-overlay"></div>
          <div class="content">
            <div class="chatbot-header">
              <label>{{ chatGroup.name }}</label>
              <div class="language-selector" style="color: blue; text-decoration: underline">
                <a [routerLink]="'/stream-chat'">{{ "try new" | translate }}</a>
              </div>
              <!--  <div class="language-selector" *ngIf="chatGroup.id === 'chatbot'">
                <div class="selected-language" (click)="toggleLanguageSelector(chatGroup.id)">
                  {{ selectedLanguage }}
                  <i class="fas fa-caret-down"></i>
                </div>
                <div class="languages-dropdown" *ngIf="languageDropdownVisible">
                                    <div class="language" *ngFor="let lang of languages"
                                        (click)="selectLanguage(lang.id)" [class.active]="lang.id === selectedLanguage">
                                        {{lang.label}}
                                    </div>
                                </div> 
              </div>-->
              <div class="chat-close-button-container">
                <button (click)="toggleMessages(chatGroup.id)">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div class="messages-container" [id]="chatGroup.id">
              <div
                class="chat-item"
                *ngFor="let chat of chatGroup.messages"
                [class.me]="chat.owner === 'me'"
                [class.chatbot]="chat.owner !== 'me'"
              >
                <div>
                  <ng-container *ngIf="chat.owner === 'me'">
                    <div class="chat-item-name-container">{{ "You" | translate }}</div>
                  </ng-container>
                  <div class="text" [innerHTML]="chat.text"></div>
                  <div class="datetime">{{ chat.displayTime }}</div>
                </div>
              </div>
            </div>
            <div class="input-container">
              <form (submit)="sendMessage(chatGroup.id)">
                <div>
                  <input
                    type="text"
                    [(ngModel)]="chatGroup.groupNewMessageInput"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <button type="submit">
                    <i class="fas fa-paper-plane"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
